// import DefaultImage from '/public/empty-product-img.png'
// import Category from './Category'
import { useCategory } from './useCategory'
export function useBrand({
  name,
  uid,
  slug,
  logo_url,
  description,
  web_url,
  mobile_url,
  categories,
  mkp_products_count,
  count,
  offers_count_by_country,
} = {}) {
  const brand = {
    name,
    slug,
    logo_url,
    description,
    wide_image: web_url,
    mobile_image: mobile_url,
    uid,
    categories,
    productsCount: mkp_products_count || count,
    offersCountByCountry: offers_count_by_country,
  }

  const logo = () => {
    // if (brand.logo_url === null || brand.logo_url === undefined || brand.logo_url === '') {
    //   return DefaultImage
    // }

    return brand.logo_url
  }

  const thumbnail = () => {
    // if (brand.logo_url === null || brand.logo_url === undefined || brand.logo_url === '') {
    //   return DefaultImage
    // }

    return brand?.logo_url?.replace('upload/', 'upload/h_30,c_thumb,f_auto/')
  }

  const hasCategories = () => {
    return brand.categories != null
  }

  const categoriesUid = () => {
    if (hasCategories()) return computeCategoriesUid()
    return []
  }

  const computeCategoriesUid = () => {
    let categoriesUid = []

    brand.categories?.forEach(rawCategory => {
      const category = useCategory(rawCategory)
      categoriesUid = categoriesUid.concat(category.activeUids)
    })

    return categoriesUid
  }

  return {
    ...brand,
    logo: logo(),
    thumbnail: thumbnail(),
    hasCategories: hasCategories(),
    categoriesUid: categoriesUid(),
    computeCategoriesUid: computeCategoriesUid(),
  }
}
